<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <!-- <div class="w-100 h-100"> -->
      <div class="container-lg pt-5 pb-5">
        <div class="row relative" v-if="isUK">
          <div class="col-md-6 col-lg-3">
            <a href="tel:+441254698771">
            <CardFooter icon="yes" title="Donation Line" content="Call us on our donation line for donations, appeals, information, queries and more at:" footer="+44 (0) 1254 698771<br/>Mon-Fri 9-5pm" bgimage="yes">
              <IconPhone color="gold" size="size28" />
            </CardFooter>
            </a>
          </div>
          <div class="col-md-6 col-lg-3">
            <CardFooter icon="icon" title="Cheque" content="Post your cheques to our head office at 185 Audley Range, Blackburn, BB1 1TH" footer="Cheques Payable to:<br/>Al-Imdaad Foundation UK" bgimage="yes">
              <IconCheque color="gold" size="size28" />
            </CardFooter>
          </div>
          <div class="col-md-6 col-lg-3">
            <a href="https://www.justgiving.com/al-imdaadfoundation" target="_blank">
            <CardFooter icon="icon" title="JustGiving" content="Click here to submit our Bank Donation Form so your donation can be processed correctly." footer="justgiving.com/ alimdaadfoundationuk" bgimage="yes">
              <IconGive color="gold" size="size28" />
            </CardFooter>
            </a>
          </div>
          <div class="col-md-6 col-lg-3">
            <CardFooter icon="icon" title="Bank Transfer" content="Use the below bank details." footer="Account No.: 01229501<br/>Sort Code: 30-00-83" bgimage="yes" class="cursor">
              <IconBank color="gold" size="size28" />
            </CardFooter>
          </div>
        </div>
        <div class="row m-0 relative" v-else>
          <div class="col-md-6 col-lg-3">
            <a href="tel:+27861786243" target="_blank">
            <CardFooter icon="yes" title="Donation Line" content="Call us on our donation line for donations, appeals, information, queries and more" footer="0861786243<br/>Mon-Fri 8am-5pm" bgimage="yes">
              <IconPhone color="gold" size="size28" />
            </CardFooter>
            </a>
          </div>
          <div class="col-md-6 col-lg-3">
            <router-link to="/waystodonate">
            <CardFooter icon="icon" title="Bank Transfer" content="Process your donation via bank transfer" footer="FNB<br/>62102373206<br/>More banking details" bgimage="yes">
              <IconBank color="gold" size="size28" />
            </CardFooter>
            </router-link>
          </div>
          <div class="col-md-6 col-lg-3">
            <router-link to="/debitorders">
            <CardFooter icon="icon" title="Debit Order" content="Set up a debit order for a recurring donation" footer="Sign up here" bgimage="yes">
              <IconRecurring color="gold" size="size28" />
            </CardFooter>
            </router-link>
          </div>
          <div class="col-md-6 col-lg-3">
            <router-link to="/contact/#donationPoints">
            <CardFooter icon="icon" title="Donation Point" content="Donate in person at an Al-Imdaad Foundation office near you" footer="Click here to find us" bgimage="yes">
              <IconBank color="gold" size="size28" />
            </CardFooter>
            </router-link>
          </div>
        </div>
      </div>
      <div class="back ">
        <div class="bg_overlay"></div>
        <div class="shape">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path class="elementor-shape-fill" d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
          </svg>
        </div>
        <div class="container-lg">
          <div class="row m-0 align-items-end h-100 py-5 ">
            <div class="col-12 p-0 relative">
              <div class="row m-0">
                <div class="col-12 text-center">
                  <div class="row m-0 justify-content-center">
                    <div class="col-auto px-2">
                      <IconRound size="large" align="center" @click="facebook" class="cursor">
                        <logo-facebook-icon />
                      </IconRound>
                    </div>
                    <div class="col-auto px-2">
                      <IconRound size="large" align="center" @click="twitter" class="cursor">
                        <logo-twitter-icon />
                      </IconRound>
                    </div>
                    <div class="col-auto px-2">
                      <IconRound size="large" align="center" @click="instagram" class="cursor">
                        <logo-instagram-icon />
                      </IconRound>
                    </div>
                    <div class="col-auto px-2">
                      <IconRound size="large" align="center" @click="youtube" class="cursor">
                        <logo-youtube-icon />
                      </IconRound>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-center big_text my-4">
                  Follow Us. Stay Updated.
                </div>
                <form  @submit.prevent="SignUpSubscription">
                  <div class="col-12 p-0 text-center mb-5">
                    <div class="row justify-content-center">
                      <div class="col-auto px-1">
                        <TextField type="text" color="gold" placeholder="First Name" v-model="signup.firstName" id="name" />
                      </div>
                      <div class="col-auto px-1">
                        <TextField type="text" color="gold" placeholder="Last Name" v-model="signup.lastName" id="lastname" />
                      </div>
                      <div class="col-auto px-1">
                        <TextField type="email" color="gold" placeholder="Email Address" v-model="signup.email" id="email" />
                      </div>
                      <div class="col-auto px-1">
                        <PhoneFieldInt type="tel" placeholder="Mobile Number" v-model:modelValue="signup.cell" v-model:theError="isValidNumber" id="mobile" color="gold" ></PhoneFieldInt>
                      </div>
                      <div class="col-auto px-1">
                        <Button type="submit" color="gold" width="100" btnText="Sign Up" :disabled="!signup.consented">Sign Up</Button>
                      </div>
                    </div>
                    <div class="row justify-content-center mt-3">
                      <div class="col-auto">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="consented" id="consented" v-model="signup.consented">
                          <label class="form-check-label light_text" for="flexCheckDefault">
                            I am happy to receive updates from Al-Imdaad Foundation<span v-if="isUK"> UK</span> via email and mobile
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center" v-if="thanks">
                      <div class="col-auto gold-text bold">
                        {{thanks}}
                      </div>
                    </div>
                  </div>
                </form>
                <div class="col-12 text-left light_text mb-3">
                  <div class="row m-0">
                    <div class="col-4">
                      <div class="row m-0">
                        <div class="col-lg-11 col-xl-10 p-0 mb-3">
                          <img :src="logo" class="img-fluid">
                        </div>
                        <div class="col-12 p-0" v-if="isUK">
                          185 Audley Range, Blackburn, BB1 1TH<br/>Charity Commission Number 1140187
                        </div>
                        <div class="col-12 p-0" v-else>
                          No.5 Smally's Court, 204 Alfred Street, Estcourt, KZN, 3310, South Africa
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row m-0">
                        <div class="col-12 p-0 mb-2 footer_header">
                          Projects
                        </div>
                        <div class="col-12 p-0 mb-1">
                          <router-link to="/ourwork">Our Work</router-link>
                        </div>
                        <div class="col-12 p-0 mb-1">
                          <router-link to="/appeals/0">Appeals</router-link>
                        </div>
                        <div class="col-12 p-0 mb-1" v-if="isUK">
                          <router-link to="/news">News & Updates</router-link>
                        </div>
                        <div class="col-12 p-0 mb-1">
                          <router-link to="/donate">Recurring Payments</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row m-0">
                        <div class="col-12 p-0 mb-2 footer_header">
                          Useful Info
                        </div>
                        <div class="col-12 p-0 mb-1">
                          <router-link to="/aboutus">About Us</router-link>
                        </div>
                        <div class="col-12 p-0 mb-1">
                          <router-link to="/donationspolicy">Donations Policy</router-link>
                        </div>
                        <div class="col-12 p-0 mb-1">
                          <router-link to="/privacypolicy">Privacy Policy</router-link>
                        </div>
                        <div class="col-12 p-0 mb-1">
                          <router-link to="/disclaimer">Disclaimer</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row m-0">
                        <div class="col-12 p-0 mb-2 footer_header">
                          Resources
                        </div>
                        <div class="col-12 p-0 mb-1">
                          <router-link to="/waystodonate">Ways to Donate</router-link>
                        </div>
                        <div class="col-12 p-0 mb-1" v-if="!isUK">
                          <router-link to="/zakaahcalculator/new">Zakaah Calculator</router-link>
                        </div>
                        <div class="col-12 p-0 mb-1" v-if="isUK">
                          <router-link to="/giftaid">GiftAid</router-link>
                        </div>
                        <div class="col-12 p-0 mb-1" v-if="user">
                          <router-link :to="{ name: 'Donor Profile', params: { id: 0 } }">My Account</router-link>
                          <!-- { name: 'user', params: { userId: 123 }} -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <transition name="slideInUp">
      <Popup v-if="thanks === 'Thank you, we have received your subscription.'" @close="thanks = false">
        <div class="row mt-4 justify-content-center text-center">
          <div class="col-7 col-md-6 text-center">
            <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
            <TickAnimation v-else />
          </div>
          <div class="col-12 mb-3 text-center medium font23 green-text">
            Sign Up Complete!
          </div>
          <div class="col-10 mb-3 text-center font15">
            Note: Please check your spam/junk folder to avoid missing updates.
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    IconRound: defineAsyncComponent(() => import('../components/IconRound.vue')),
    TextField: defineAsyncComponent(() => import('../components/TextField.vue')),
    TickAnimation: defineAsyncComponent(() => import('../components/animations/TickAnimation.vue')),
    PhoneFieldInt: defineAsyncComponent(() => import('../components/PhoneFieldInt.vue')),
    IconBank: defineAsyncComponent(() => import('../components/icons/IconBank.vue')),
    IconCheque: defineAsyncComponent(() => import('../components/icons/IconCheque.vue')),
    IconGive: defineAsyncComponent(() => import('../components/icons/IconGive.vue')),
    IconPhone: defineAsyncComponent(() => import('../components/icons/IconPhone.vue')),
    IconRecurring: defineAsyncComponent(() => import('../components/icons/IconRecurring.vue')),
    CardFooter: defineAsyncComponent(() => import('./CardFooter.vue')),
    Popup: defineAsyncComponent(() => import('./Popup.vue')),
    'logo-facebook-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-facebook.vue')),
    'logo-twitter-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-twitter.vue')),
    'logo-instagram-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-instagram.vue')),
    'logo-youtube-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-youtube.vue'))
  },
  name: 'BottomNav',
  data () {
    return {
      signup: {
        firstName: '',
        lastName: '',
        cell: '',
        email: '',
        consented: false
      },
      thanks: false,
      logo: process.env.VUE_APP_LOGO,
      isValidNumber: true
    }
  },
  computed: {
    ...mapGetters([
      'user', 'isApp'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    isValid () {
      if (this.signup.cell && !this.isValidNumber) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions(['subscribeTo']),
    async SignUpSubscription () {
      const ret = await this.subscribeTo(this.signup)
      if (ret.message) {
        this.thanks = ret.message
        setTimeout(() => {
          this.thanks = false
        }, 5000)
      } else {
        this.signup = {}
        this.thanks = 'Thank you, we have received your subscription.'
      }
    },
    facebook () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        window.open('https://en-gb.facebook.com/AlImdaadFoundationUK/', '_blank')
      } else {
        window.open('https://web.facebook.com/alimdaadhq', '_blank')
      }
    },
    twitter () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        window.open('https://twitter.com/Alimdaad_UK', '_blank')
      } else {
        window.open('https://twitter.com/Alimdaad', '_blank')
      }
    },
    instagram () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        window.open('https://www.instagram.com/alimdaadfoundationuk/', '_blank')
      } else {
        window.open('https://www.instagram.com/alimdaad/', '_blank')
      }
    },
    youtube () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        window.open('https://www.youtube.com/user/ALIMDAAD', '_blank')
      } else {
        window.open('https://www.youtube.com/user/ALIMDAAD', '_blank')
      }
    }
  }
}
</script>

<style scoped>
  .container-lg {
    position: relative;
  }
  .big_text {
    color: #FFFFFF;
    font-size: 41px;
    font-weight: 300;
  }
  .light_text {
    color: var(--green-color-lighter);
  }
  .footer_header {
    font-size: 22px;
    font-weight: 300;
    color: var(--gold-color);
  }
  .abs {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: -25%;
    z-index: 1;
  }
  .relative {
    z-index: 2;
  }
  a {
    color: var(--green-color-lighter);
    text-decoration: none;
  }
  a:hover {
    color: var(--green-color-light);
    text-decoration: none;
  }
@media (min-width: 992px) {
  .back {
    background-color: transparent;
    background-image: linear-gradient(180deg, var( --e-global-color-5160f20 ) 24%, var( --e-global-color-5ee8269 ) 100%);
    background-image: linear-gradient(180deg, #0D351C 0%, #1A6836 70%);
    position: relative;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: -311px;
    margin-bottom: 0px;
    padding: 250px 0px 26px 0px;
    z-index: 0;
  }
  .bg_overlay {
    background-image: url(../assets/images/p.png);
    background-size: 17% auto;
    opacity: 0.05;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  .shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    top: -1px;
    transform: rotate(180deg);

  }
  .shape svg {
    width: calc(211% + 1.3px);
    height: 500px;
    display: block;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
</style>
